import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, getLangIndex } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'

import Partners from '../components/index/partners'
import Benefits from '../components/index/benefits'
import Article from '../components/index/article'
import Poster from '../components/index/poster'
import Cta from '../components/index/cta'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      script
      noscript
    }

    mainPages: allSanityMainPage(limit: 6) {
      edges {
        node {
          title
          benefits {
            title
            text
            metrics
            peracentage
          }
          articles {
            identifier
            title
            text
            image {
              asset {
                _id
              }
            }
            fullWidth
            addShadow
            right
            buttons {
                caption
                to
                outlined
              }
            links {
                caption
                to
                outlined
              }
          }
          cta {
            title
            text
            buttons {
              caption
              to
              outlined
            }
          }
          goodCompany
          partners {
            title
            image {
              asset {
                _id
              }
            }
            links {
              caption
              to
            }
          }
          poster {
            title
            text
            features
            buttons {
              caption
              to
              outlined
            }
            centerImage {
              asset {
                _id
              }
            }
            leftImage {
              asset {
                _id
              }
            }
            rightImage {
              asset {
                _id
              }
            }
            asset {
              _id
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, errors, lang, pageContext }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const mainPages = (data || {}).mainPages
    ? mapEdgesToNodes(data.mainPages)
    : []

  const currentLang = getLangIndex(mainPages, lang)

  const Articles = ({ mainPages, lang }) => {
    const currentLang = getLangIndex(mainPages, lang)

    const { articles } = mainPages[currentLang]

    if (!articles) {
      return []
    }
    return articles.map((a, i) => <Article article={a} key={i} />)
  }

  return (
    <Layout pageContext={pageContext}>
      <Container>
        {mainPages[currentLang].poster && (
          <Poster mainPages={mainPages} />
        )}

        {mainPages[currentLang].benefits && (
          <Benefits mainPages={mainPages} />
        )}

        {mainPages[currentLang].articles && (
          <Articles mainPages={mainPages} />
        )}

        {mainPages[currentLang].partners && (
          <Partners mainPages={mainPages} />
        )}

        {mainPages[currentLang].cta && (
          <Cta mainPages={mainPages} />
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
