import React from 'react'

import * as styles from './appLink.module.css'
import ArrowIcon from './icons/arrow'
import Link from './link'

const AppLink = ({ to, caption }) => (
  <Link className={styles.link} to={to}>
    <span>{caption}</span>
    <ArrowIcon />
  </Link>
)

export default AppLink
