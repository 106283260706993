import React from 'react'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj, cn } from '../../lib/helpers'

import * as styles from './poster-images.module.css'

const PosterImages = ({ poster }) => {
  return (
    <div className={styles.images}>
      <img className={cn(styles.leftImage, 'lazyload')} data-src={imageUrlFor(buildImageObj(poster.leftImage)).url()} />
      <img className={cn(styles.rightImage, 'lazyload')} data-src={imageUrlFor(buildImageObj(poster.rightImage)).url()} />
      <img className={cn(styles.centerImage, 'lazyload')} data-src={imageUrlFor(buildImageObj(poster.centerImage)).url()} />
    </div>
  )
}

export default PosterImages
