import React from 'react'
import Typist from 'react-typist'
import Button from '../button'
import EmojiHandIcon from '../icons/emoji-hand'

import Animation from '../animation/animation'
import AnimationData from '../animation//data.json'

import PosterImages from './poster-images'

import * as styles from './poster.module.css'

const Poster = ({ mainPages, lang }) => {
  const withAnimation = false

  const langIndex = mainPages.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0
  const { poster } = mainPages[currentLang]

  const buttons = () => {
    return poster.buttons && poster.buttons.map((b, i) => (
      <Button to={b.to} outlined={b.outlined} key={i}>
        <span>
          {b.caption}
        </span>
      </Button>
    ))
  }

  return (
    <div className={styles.poster}>
      <div className={styles.details}>
        <h1>
          <Typist cursor={{
            hideWhenDone: true,
            hideWhenDoneDelay: 0
          }}
          >
            {poster.title}
          </Typist>
        </h1>
        <p>{poster.text}</p>

        {poster.buttons && (
          <div className={styles.buttons}>
            {buttons()}
          </div>
        )}

        {poster.features && (
          <div className={styles.features}>
            <span>{poster.features[0]}</span>
            <span className={styles.hand}>
              <EmojiHandIcon />
            </span>
            <span>{poster.features[1]}</span>
          </div>
        )}

      </div>
      {withAnimation
        ? <Animation data={AnimationData} loop play />
        : <PosterImages poster={poster} />}
    </div>
  )
}

export default Poster
