import React from 'react'
import Button from '../button'

import * as styles from './cta.module.css'

const Cta = ({ mainPages, lang }) => {
  const langIndex = mainPages.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0
  const { cta } = mainPages[currentLang]

  const buttons = () => {
    return cta.buttons && cta.buttons.map((b, i) => (
      <Button to={b.to} outlined={b.outlined} white key={i}>
        {b.caption}
      </Button>
    ))
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h2>{cta.title}</h2>
        <p>{cta.text}</p>
        {cta.buttons && (
          <div className={styles.buttons}>
            {buttons()}
          </div>
        )}
      </div>
    </div>
  )
}

export default Cta
