import React from 'react'
import { cn, buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import AppLink from '../appLink'
import Button from '../button'

import * as styles from './article.module.css'

const Article = ({ article }) => {
  const links = () => article.links &&
    article.links.map((l, i) => (
      <AppLink to={l.to} caption={l.caption} key={i} />
    ))

  const buttons = () => article.buttons &&
    article.buttons.map((b, i) => (
      <Button to={b.to} outlined={b.outlined} key={i}>
        {b.caption}
      </Button>
    ))

  return (
    <div
      id={article.identifier || ''}
      className={cn(
        styles.article,
        article.fullWidth && styles.fullWidth,
        article.addShadow && styles.addShadow,
        article.right && styles.right
      )}
    >
      <div className={styles.details}>
        <h2>{article.title}</h2>
        <p>{article.text}</p>
        {article.buttons.length > 0 && (
          // <div className={styles.buttons}>
          <div>
            {buttons()}
          </div>
        )}
        {article.links && (
          // <div className={styles.links}>
          <div>
            {links()}
          </div>
        )}
      </div>
      <div className={styles.image}>
        <img data-src={imageUrlFor(buildImageObj(article.image)).url()} className='lazyload' alt={article.title || 'article image'} />
      </div>

    </div>
  )
}

export default Article
