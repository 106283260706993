import React from 'react'

import * as styles from './benefits.module.css'

const Benefits = ({ mainPages, lang }) => {
  const getBenefits = () => {
    const chartValue = (peracentage) => `${peracentage * 2.83}, 283`

    const langIndex = mainPages.findIndex(g => g.title === lang)
    const currentLang = langIndex !== -1 ? langIndex : 0
    const { benefits } = mainPages[currentLang]

    return benefits && benefits.map((f, i) => (
      <div className={styles.benefit} key={i}>
        <svg
          viewBox='0 0 102 102'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='xMidYMid'
          width='102'
          height='102'
        >
          <circle cx='51' cy='51' r='45' stroke='#FFFFFF' strokeWidth='12' fill='none' />
          <circle
            className={styles.value}
            cx='51'
            cy='51'
            r='45'
            stroke='#05A081'
            strokeWidth='12'
            strokeDasharray={chartValue(f.peracentage)}
            fill='none'
            transform='rotate(-90 51 51)'
            strokeLinecap='round'
          />
          <text x='54' y='57' fill='#A4AAB0' textAnchor='middle' className={styles.metrics}>{f.metrics}</text>
        </svg>

        <b>{f.title}</b>
        <span>{f.text}</span>
      </div>
    ))
  }

  return (
    <div className={styles.benefits}>
      <div className={styles.container}>
        {getBenefits()}
      </div>
    </div>
  )
}

export default Benefits
