import React from 'react'

const ArrowIcon = () => (
  <svg
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='17'
    height='16'
  >
    <g clipPath="url(#clip0)">
      <path d="M13.1867 8.28848L12.4651 9.11689L9.48159 12.542C9.48153 12.542 9.48148 12.5421 9.48142 12.5422C9.45586 12.5716 9.43767 12.6043 9.42674 12.638C9.41581 12.6717 9.41206 12.7066 9.41538 12.7409C9.42199 12.8095 9.45731 12.8786 9.52217 12.9301C9.58777 12.9823 9.67659 13.0101 9.76888 13.0019C9.86105 12.9938 9.94071 12.9514 9.99284 12.8914L9.99326 12.8909L14.0872 8.19537C14.0881 8.19397 14.0891 8.19256 14.09 8.19114C14.113 8.13004 14.142 8.08393 14.162 8.05594C14.1663 8.03836 14.1686 8.02058 14.169 8.00283C14.1684 7.97588 14.1634 7.94886 14.1539 7.92259C14.1401 7.90248 14.1233 7.87562 14.1072 7.8428C14.1011 7.83176 14.0944 7.82091 14.0872 7.8103L9.99326 3.11474L9.99318 3.11465C9.96448 3.08172 9.92708 3.0536 9.88268 3.03376C9.83823 3.0139 9.78876 3.00325 9.73799 3.00333L9.73628 3.00333C9.65507 3.00317 9.57923 3.03036 9.52168 3.0759L13.1867 8.28848ZM13.1867 8.28848H12.0881M13.1867 8.28848H12.0881M12.0881 8.28848H2.33946C2.24696 8.28848 2.16254 8.25314 2.10371 8.19692C2.04565 8.14142 2.01749 8.07111 2.01749 8.00283C2.01749 7.93456 2.04565 7.86424 2.10371 7.80875C2.16254 7.75252 2.24696 7.71719 2.33946 7.71719H12.0881H13.1867L12.4651 6.88878L9.48132 3.46337L9.48117 3.46321C9.45553 3.43379 9.43728 3.40109 9.42633 3.36741C9.41539 3.33379 9.41165 3.299 9.41495 3.26472L8.91726 3.21671M12.0881 8.28848L8.91726 3.21671M8.91726 3.21671L9.41495 3.26472C9.41826 3.23042 9.42868 3.19618 9.44648 3.16392C9.4643 3.13162 9.48945 3.10147 9.52158 3.07598L8.91726 3.21671Z" stroke="#008060"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16.1865" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default ArrowIcon