import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import HandIcon from '../icons/hand'
import AppLink from '../appLink'

import * as styles from './partners.module.css'

const Partners = ({ mainPages, lang }) => {
  const langIndex = mainPages.findIndex(g => g.title === lang)
  const currentLang = langIndex !== -1 ? langIndex : 0
  const { partners } = mainPages[currentLang]

  const getPartners = () => partners && partners.map((p, i) => {
    const links = p.links
      ? p.links.map((l, i) => <AppLink to={l.to} caption={l.caption || 'go to the website'} key={i} />)
      : []

    return (
      <div className={styles.partner} key={i}>
        <h3>{p.title}</h3>
        <img data-src={imageUrlFor(buildImageObj(p.image)).url()} className='lazyload' />
        {links}
      </div>
    )
  })

  if (!partners) {
    return []
  }

  return (
    <div className={styles.partners}>
      <div className={styles.caption}>
        <h2>
          {mainPages[currentLang].goodCompany || 'You are in good company'}
          <HandIcon />
        </h2>
      </div>
      <div className={styles.list}>
        {getPartners()}
      </div>
    </div>
  )
}

export default Partners
